import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import {
	LazyLoadImage,
	LazyLoadComponent,
} from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

const NarmPractitioner4Page = () => (
	<Layout>
		<SEO
			title="NARM Practitioner Training Manual Module 4"
			description="NARM Practitioner Training Manual Module 4"
		/>
		<div class="page-headline">
			<div class="container">
				<div class="section-heading text-center">
					<h1>NARM Practitioner Training Manual Module 4</h1>
				</div>
			</div>
		</div>
		<div class="page-content narm-student">
			<div class="container">
				<div class="row">
					<div class="col-md-10 m-auto text-center">
						<LazyLoadImage
							effect="blur"
							src="/assets/img/module-4-square.png"
							class="img-fluid mb-4"
							alt="Narm Practitioner Training 4"
						/>
						<a
							className="btn btn-primary inverted shadow arrow"
							href="https://www.dropbox.com/sm/password?cont=https%3A%2F%2Fwww.dropbox.com%2Fs%2Fu1jwpjuwkhscex9%2FNARM%2520Training%2520Module%25204%2520of%25204%2520-%2520FULL%2520MANUAL.pdf%3Fdl%3D0&content_id=AN6F2G3OLhMbFXQpH7Uz0vxgw2t_e9LfL_A"
							target="_blank"
						>
							Download Manual
						</a>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default NarmPractitioner4Page
